import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';
import ServerConfig from '../../public/ServerConfig.json'

export default class AssociatePIN {

	getBaseURL() {
		return ServerConfig.ServerIP;
	}

	verifyAssociate(PIN, associateType) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		let api = '';

		if (associateType == 'associate') {
			api = 'employee/check_employee_credential/';
		}
		else {
			api = 'employee/check_manager_credential/';
		}

		const formData = new FormData();
		formData.append('employee_pin', PIN);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}