import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';
import ServerConfig from '../../public/ServerConfig.json'

export default class AttendanceService {

	getBaseURL() {
		return ServerConfig.ServerIP;
	}

	getAssociate(postObj) {

		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'attendence/index/';
		const formData = new FormData();

		const storeCodes = [];

		postObj.assignedBranchId.forEach(function (value) {
			storeCodes.push(value.code);
		});

		formData.append('filter', postObj.filter);
		formData.append('date1', postObj.date1);
		formData.append('date2', postObj.date2);
		formData.append('branches', JSON.stringify(storeCodes));

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	logoutAssociate(ID, associatePIN, image) {

		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'attendence/check_out/';
		const formData = new FormData();
		formData.append('attendence_id', ID);
		formData.append('employee_id', associatePIN);
		formData.append('image', image);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	loginAssociate(associatePIN, image) {

		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'attendence/add_timein/';
		const formData = new FormData();
		formData.append('employee_id', associatePIN);
		formData.append('image', image);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	availableSurvey(associatePIN) {

		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'attendence/check_employee_available_survey/';
		const formData = new FormData();
		formData.append('employee_id', associatePIN);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	saveSurvey(associatePIN, answer, surveyID) {

		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'survey/save_associate_survey/';
		const formData = new FormData();
		formData.append('survey_id', surveyID);
		formData.append('emp_pin_id', associatePIN);
		formData.append('action_type', answer);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getBranchBasic() {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'announcement/get_branch_basic/';
		return instance().get(api).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}