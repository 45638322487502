import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';
import ServerConfig from '../../public/ServerConfig.json'

export default class Backroom {

	getBaseURL() {
		return ServerConfig.ServerIP;
	}

	customerReceipt(receiptID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'order_list/preview_customer_receipt/' + receiptID;
		return instance().get(api)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getPrepReceipt(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'order_list/get_prep_receipt/';
		const formData = new FormData();
		formData.append('invoice_id', postObj.itemID);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getOrderStatus(postObj, invType, orderStatus) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'order_list/get_order_detail/';
		const formData = new FormData();
		formData.append('invoice_id', postObj.itemID);
		formData.append('inv_type', invType);
		formData.append('working_status', orderStatus);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getRackList(fetchDate) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'order_list/ajax_rack/';
		const formData = new FormData();
		formData.append('date', fetchDate);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getProgressList(fetchDate) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'order_list/ajax_pending_orders/';
		const formData = new FormData();
		formData.append('date', fetchDate);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getReadyList(fetchDate) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'order_list/ajx_completed_orders/';
		const formData = new FormData();
		formData.append('date', fetchDate);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	notifyCustomer(expressType, receiptID, salesId) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'order_list/re_send_order_notifaction/'
			;
		const formData = new FormData();
		formData.append('express_type', expressType);
		formData.append('invoice_id', receiptID);
		formData.append('sales_id', salesId);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	savePrep(product, items, actionType) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'order_list/add_prep_data_order/';
		const formData = new FormData();

		formData.append('invoice_id', product.itemID);
		formData.append('item_id', JSON.stringify(items));
		formData.append('employee_id', product.pin);
		formData.append('action_type', actionType);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	saveOrderStatus(product, id, Bills, serviceName, actionType) {
		//SHOW LOADING
		const store = useStore();

		store.dispatch(ActionTypes.PROGRESS_BAR, true);

		const formData = new FormData();

		formData.append('invoice_id', product.itemID);
		formData.append('order_items', JSON.stringify(id));
		formData.append('employee_id', product.pin);
		formData.append('order_total_bill', JSON.stringify(Bills));
		formData.append('service_name', JSON.stringify(serviceName));

		let api = '';

		if (actionType == 'start') {
			api = 'order_list/set_order_to_employee';
		}
		else if (actionType == 'ready') {
			api = 'order_list/completed_order_to_employee';
		}
		else if (actionType == 'cancel') {
			api = 'order_list/cancel_order_to_employee';
		}
		else {
			api = '';
		}

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	scanReceipt(product, actionType) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'order_list/add_scan_data_order/';
		const formData = new FormData();

		formData.append('inv_item', product.itemID);
		formData.append('emp_id', product.pin);
		formData.append('action_type', actionType);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	associateOrderPreview(receiptID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'order_list/get_associate_pending_order/';

		const formData = new FormData();
		formData.append('invoice_id', receiptID);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	changeStatus(data) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Services/change_status/' + data.status + '/' + data.id;
		return instance().get(api).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}