import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';
import ServerConfig from '../../public/ServerConfig.json'

export default class ApprovalService {

	getBaseURL() {
		return ServerConfig.ServerIP;
	}

	getAssociateShifts(weekDate) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'manage_shifts/index/';
		const formData = new FormData();
		formData.append('week_date', weekDate);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getAssociateLogin(associateID, weekStart, weekEnd) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'manage_shifts/associate_login/';
		const formData = new FormData();
		formData.append('employee_id', associateID);
		formData.append('week_start', weekStart);
		formData.append('week_end', weekEnd);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}


	postCheckListApprove(scheduleID, list, managerApproved) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'manage_shifts/associate_approve_hrs/';
		const formData = new FormData();
		formData.append('schedule_id', scheduleID);
		formData.append('manager_approved', managerApproved);
		formData.append('list', JSON.stringify(list));
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	updateManualHours(approveList, scheduleID, managerID) {

		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'manage_shifts/add_manual_hrs/';
		const formData = new FormData();
		formData.append('counter_id', approveList.counterId);
		formData.append('branch_manager_id', managerID);
		formData.append('schedule_id', scheduleID);
		formData.append('aprroved_hrs', approveList.hours);
		formData.append('aprroved_bx', approveList.freezed);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	addAssociateLogin(loginTime, employeeID, managerID, logoutTime, loginDate) {

		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'attendence/add_login_timing/';
		const formData = new FormData();
		formData.append('login_time', loginTime);
		formData.append('manager_id', managerID);
		formData.append('employee_id', employeeID);
		formData.append('logout_time', logoutTime);
		formData.append('login_date', loginDate);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	updateAssociateLogin(loginTime, attendenceID, managerID, logoutTime, loginDate) {

		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'attendence/update_login_timing/';
		const formData = new FormData();
		formData.append('login_time', loginTime);
		formData.append('manager_id', managerID);
		formData.append('attendence_id', attendenceID);
		formData.append('logout_time', logoutTime);
		formData.append('login_date', loginDate);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}