
import { createRouter,createWebHashHistory } from "vue-router";
import AppShell from '@/pages/wrapper/AppShell.vue';
import Homepage from '@/pages/homepage/Homepage.vue';
import Services from '@/pages/services/Services.vue';
import Login from '@/pages/login/Login.vue';
import Departments from '@/pages/departments/Departments.vue';
import Items from '@/pages/items/Items.vue';
import ItemDetails from '@/pages/itemdetails/ItemDetails.vue';
import Initialization from '@/pages/initialization/Initialization.vue';
import DescriptionTags from '@/pages/descriptiontags/DescriptionTags.vue';
import CorporatesHead from '@/pages/corporateshead/CorporatesHead.vue';
import Regions from '@/pages/regions/Regions.vue';
import Announcements from '@/pages/announcements/Announcements.vue';
import DryCleaner from '@/pages/drycleaners/DryCleaners.vue';
import Branches from '@/pages/branches/Branches.vue';
import PrinterSetups from '@/pages/printersetups/PrinterSetups.vue';
import ReceiptPrinters from '@/pages/receiptprinter/ReceiptPrinters.vue';
import StoreDryCleaners from '@/pages/storedrycleaner/StoreDryCleaners.vue';
import Survey from '@/pages/survey/Survey.vue';
import Coupon from '@/pages/coupons/Coupons.vue';
import Backroom from '@/pages/backroom/Backroom.vue';
import OnRack from '@/pages/backroom/OnRack.vue';
import StoreExpenses from '@/pages/storeexpenses/StoreExpenses.vue';
import OnProgress from '@/pages/backroom/OnProgress.vue';
import CorporateBranches from '@/pages/corporatebranches/CorporateBranches.vue';
import ProjectedSales from '@/pages/projectedsales/ProjectedSales.vue';
import UpChargeLists from '@/pages/upchargelists/UpChargeLists.vue';
import Associates from '@/pages/associates/Associates.vue';
import Customers from '@/pages/customers/Customers.vue';
import { useStore } from "@/store.ts";

import AppCheckoutShell from '@/pages/wrapper/AppCheckoutShell.vue';
import ScheduleAssistance from '@/pages/schedule/ScheduleAssistance.vue';
import HoursApproval from '@/pages/hoursApproval/HoursApproval.vue';
import Attendance from '@/pages/attendance/Attendance.vue';
import OnReady from '@/pages/backroom/OnReady.vue';
import Transactions from '@/pages/transaction/Transactions.vue';
import CashRegister from '@/pages/cashRegister/CashRegister.vue';
import CheckIn from '@/pages/checkIn/CheckIn.vue';

const ifAuthenticated = (_, __, next) => {

  const store = useStore();
  if (store.getters.isAuthenticated == '') next({ path: '/login' })
  else next()

}

const ifNotAuthenticated = (_, __, next) => {

  const store = useStore();
  if (store.getters.isAuthenticated == '') next()
  else next({ path: '/store/dashboard' })

}

const routes = [
  { path: '/', redirect: "/store/dashboard", beforeEnter: ifAuthenticated },
  { path: '/login', name: 'login', component: Login, beforeEnter: ifNotAuthenticated },
  { path: '/check-in', name: 'check-in', component: CheckIn, beforeEnter: ifAuthenticated },
  {
    path: '/backroom',
    redirect: "/backroom/on-rack",
    name: 'backroom',
    component: Backroom,
    beforeEnter: ifAuthenticated,
    children: [
      { path: 'on-rack', name: 'on-rack', component: OnRack },
      { path: 'on-progress', name: 'on-progress', component: OnProgress },
      { path: 'on-ready', name: 'on-ready', component: OnReady },
    ]
  },
  {
    path: '/process',
    name: 'app_checkout_shell',
    component: AppCheckoutShell,
    beforeEnter: ifAuthenticated,
    children: [
      { path: 'transactions', name: 'transactions', component: Transactions },
    ]
  },
  {
    path: '/store',
    name: 'app_shell',
    component: AppShell,
    beforeEnter: ifAuthenticated,
    children: [
      { path: 'hours-approval', name: 'HoursApproval', component: HoursApproval },
      { path: 'schedule', name: 'schedule', component: ScheduleAssistance },
      { path: 'initialization', name: 'initialization', component: Initialization },
      { path: 'stores', name: 'stores', component: Branches },
      { path: 'services', name: 'services', component: Services },
      { path: 'dashboard', name: 'dashboard', component: Homepage },
      { path: 'departments', name: 'departments', component: Departments },
      { path: 'items', name: 'items', component: Items },
      { path: 'item-detail', name: 'ItemDetails', component: ItemDetails },
      { path: 'description-tag', name: 'DescriptionTags', component: DescriptionTags },
      { path: 'corporates-head', name: 'CorporatesHead', component: CorporatesHead },
      { path: 'regions', name: 'Regions', component: Regions },
      { path: 'dry-cleaner', name: 'DryCleaner', component: DryCleaner },
      { path: 'announcement', name: 'Announcements', component: Announcements },
      { path: 'store-dry-cleaner', name: 'StoreDryCleaners', component: StoreDryCleaners },
      { path: 'receipt-printer', name: 'ReceiptPrinters', component: ReceiptPrinters },
      { path: 'printer-setup', name: 'PrinterSetups', component: PrinterSetups },
      { path: 'survey', name: 'Survey', component: Survey },
      { path: 'coupon', name: 'Coupon', component: Coupon },
      { path: 'store-expense', name: 'StoreExpenses', component: StoreExpenses },
      { path: 'corporate-braches', name: 'CorporateBranches', component: CorporateBranches },
      { path: 'project-sales', name: 'ProjectSales', component: ProjectedSales },
      { path: 'up-charge-lists', name: 'UpChargeLists', component: UpChargeLists },
      { path: 'associates', name: 'Associates', component: Associates },
      { path: 'customers', name: 'Customers', component: Customers },
      { path: 'attendance', name: 'Attendance', component: Attendance },
      { path: 'cash-register', name: 'CashRegister', component: CashRegister },
      { path: 'initialization', name: 'initialization', component: Initialization },
      { path: 'stores', name: 'stores', component: Branches },
      { path: 'services', name: 'services', component: Services },
      { path: 'dashboard', name: 'dashboard', component: Homepage },
      { path: 'departments', name: 'departments', component: Departments },
      { path: 'items', name: 'items', component: Items },
      { path: 'item-detail', name: 'ItemDetails', component: ItemDetails },
      { path: 'description-tag', name: 'DescriptionTags', component: DescriptionTags },
      { path: 'corporates-head', name: 'CorporatesHead', component: CorporatesHead },
      { path: 'regions', name: 'Regions', component: Regions },
      { path: 'dry-cleaner', name: 'DryCleaner', component: DryCleaner },
      { path: 'announcement', name: 'Announcements', component: Announcements },
      { path: 'store-dry-cleaner', name: 'StoreDryCleaners', component: StoreDryCleaners },
      { path: 'receipt-printer', name: 'ReceiptPrinters', component: ReceiptPrinters },
      { path: 'printer-setup', name: 'PrinterSetups', component: PrinterSetups },
      { path: 'survey', name: 'Survey', component: Survey },
      { path: 'store-expense', name: 'StoreExpenses', component: StoreExpenses },
    ]
  }
]


const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// const router = createRouter({
//   history: createWebHistory(''),
//   routes,
// });

export default router;