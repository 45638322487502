import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';
import ServerConfig from '../../public/ServerConfig.json'

export default class HomepageService {

	getBaseURL() {
		return ServerConfig.ServerIP;
	}

	getHompageDetails(searchForm) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'homepage/index/';
		const formData = new FormData();
		formData.append('date1',searchForm.date1);
		formData.append('date2',searchForm.date2);
		formData.append('pre_date',searchForm.filter);
		formData.append('store_id',searchForm.assignedBranchId.code);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getBranchBasic() {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'announcement/get_branch_basic/';
		return instance().get(api).then(res => res.data)
		.catch((e) => ExceptionHandling.HandleErrors(e))
		.finally(() => {
			store.dispatch(ActionTypes.PROGRESS_BAR, false);
		})
	}
}