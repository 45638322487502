import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';
import ServerConfig from '../../public/ServerConfig.json'


export default class SearchTransaction {

	getBaseURL() {
		return ServerConfig.ServerIP;
	}

	searchItem(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'single_checkout/search_invoices/';
		const formData = new FormData();
		formData.append('search_customer', postObj.receiptNO);
		formData.append('filter_dates', postObj.searchFilter);
		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}