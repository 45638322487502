import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';
import ServerConfig from '../../public/ServerConfig.json'

export default class Announcement {

	getBaseURL() {
		return ServerConfig.ServerIP;
	}

	getItems(data) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'announcement/index/';
		const formData = new FormData();
		formData.append('filter', data.filter.value);
		formData.append('date1', data.dateFrom);
		formData.append('date2', data.dateTo);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			})
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	saveItem(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'announcement/add_announcement/';
		const formData = new FormData();

		const storeCodes = [];

		postObj.assignedBranchId.forEach(function(value) {
			storeCodes.push(value.code);
		});

		formData.append('notification', postObj.notification.value);
		formData.append('branch_manager_id', postObj.managerId);
		formData.append('title', postObj.title);
		formData.append('description', postObj.description);
		formData.append('type', postObj.annoucementsType.value);
		formData.append('branches',JSON.stringify(storeCodes));

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	updateItem(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'announcement/edit_announcement/';
		const formData = new FormData();

		const storeCodes = [];

		postObj.assignedBranchId.forEach(function(value) {
			storeCodes.push(value.code);
		});

		formData.append('annoucement_id', postObj.annoucementId);
		formData.append('notification', postObj.notification.value);
		formData.append('branch_manager_id', postObj.managerId);
		formData.append('title', postObj.title);
		formData.append('description', postObj.description);
		formData.append('type', postObj.annoucementsType.value);
		formData.append('branches',JSON.stringify(storeCodes));

		return instance() (
		{
			method: 'post',
			url: api,
			data: formData,
		}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getItem(id) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'announcement/single_announcement/' + id;
		return instance().get(api)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getBranchBasic() {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'announcement/get_branch_basic/';
		return instance().get(api).then(res => res.data)
		.catch((e) => ExceptionHandling.HandleErrors(e))
		.finally(() => {
			store.dispatch(ActionTypes.PROGRESS_BAR, false);
		})
	}

	changeStatus(data) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'announcement/change_status/' + data.status + '/' + data.annoucementId;
		return instance().get(api).then(res => res.data)
		.catch((e) => ExceptionHandling.HandleErrors(e))
		.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
		})
	}
}