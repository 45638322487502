import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';
import ServerConfig from '../../public/ServerConfig.json'

export default class CorporateBranch {

	getBaseURL() {
		return ServerConfig.ServerIP;
	}

	getItems(start) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'corporates/index/' + start;
		return instance().get(api)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	saveItem(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'corporates/add_corporate/';
		const formData = new FormData();

		const extraInfo = [];

		postObj.extraInfo.forEach(function (value) {
			extraInfo.push(value.fieldname);
		});

		formData.append('corporate_head_id', postObj.corpHead.corporate_id);
		formData.append('account_type', postObj.accounttype.key);
		formData.append('title', postObj.title);
		formData.append('address', postObj.address);
		formData.append('contact', postObj.contact);
		formData.append('email_address', postObj.email);
		formData.append('description', postObj.description);
		formData.append('emp_no', postObj.empNo);
		formData.append('price_list_id', postObj.schedule.id);
		formData.append('po_no', postObj.poNo);
		formData.append('req_form', postObj.reqForm);
		formData.append('meta_field_name', JSON.stringify(extraInfo));
		formData.append('fax', postObj.fax);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	updateItem(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'corporates/edit_corporate/';
		const formData = new FormData();

		const extraInfo = [];

		postObj.extraInfo.forEach(function (value) {
			extraInfo.push(value.fieldname);
		});

		formData.append('corporate_main_id', postObj.id);
		formData.append('corporate_head_id', postObj.corpHead.corporate_id);
		formData.append('account_type', postObj.accounttype.key);
		formData.append('title', postObj.title);
		formData.append('address', postObj.address);
		formData.append('contact', postObj.contact);
		formData.append('email_address', postObj.email);
		formData.append('description', postObj.description);
		formData.append('emp_no', postObj.empNo);
		formData.append('price_list_id', postObj.schedule.id);
		formData.append('po_no', postObj.poNo);
		formData.append('req_form', postObj.reqForm);
		formData.append('meta_field_name', JSON.stringify(extraInfo));
		formData.append('fax', postObj.fax);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getItem(data) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'corporates/single_corporate/' + data.corporate_main_id;
		return instance().get(api)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	changeStatus(data) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'corporates/change_status/' + data.status + '/' + data.id;
		return instance().get(api).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getSearchedCorpBranch(keyword) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'corporates/search_for_corp_branch/';

		const formData = new FormData();
		formData.append('keyword', keyword);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}