import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';
import ServerConfig from '../../public/ServerConfig.json'

export default class Assocaite {

	getBaseURL() {
		return ServerConfig.ServerIP;
	}

	getItems(keyword) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'employee/search_employee/';
		const formData = new FormData();
		formData.append('search_employee', keyword);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			})
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	openDialog() {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'employee/add_employee/';
		return instance().get(api)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	//SAVE ASSOCIATE IN DATABASE
	saveItem(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'employee/add_new_employee/';
		const formData = new FormData();


		formData.append('employee_id', postObj.associateID);
		formData.append('firstname', postObj.firstName);
		formData.append('lastname', postObj.lastName);
		formData.append('email_address', postObj.email);
		formData.append('address', postObj.address);
		formData.append('description', postObj.description);
		formData.append('hourly_rate', postObj.hourlyRate);
		formData.append('average_alteration', postObj.maa);
		formData.append('magic', postObj.mimn);
		formData.append('homephone', postObj.homePhone);
		formData.append('city', postObj.city);
		formData.append('companyext', postObj.companyExt);
		formData.append('job_title', postObj.jobTitle);
		formData.append('performance', postObj.performanceNo);
		formData.append('zip', postObj.zipCode);
		formData.append('hire_date', postObj.hireDate);
		formData.append('pin', postObj.pin);

		formData.append('region_id', postObj.region.region_id);
		formData.append('job_class', postObj.jobClass.value);
		formData.append('branch_id', postObj.homeStore.code);
		formData.append('branch_id_2', postObj.store2.code);
		formData.append('branch_id_3', postObj.store3.code);
		formData.append('manager', postObj.manager.employee_id);
		formData.append('employee_type', postObj.associateType.value);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	//UPDATE ASSOCIATE
	updateItem(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'employee/edit_new_employee/';
		const formData = new FormData();

		formData.append('employee_id', postObj.associateID);
		formData.append('firstname', postObj.firstName);
		formData.append('lastname', postObj.lastName);
		formData.append('email_address', postObj.email);
		formData.append('address', postObj.address);
		formData.append('description', postObj.description);
		formData.append('hourly_rate', postObj.hourlyRate);
		formData.append('average_alteration', postObj.maa);
		formData.append('magic', postObj.mimn);
		formData.append('homephone', postObj.homePhone);
		formData.append('city', postObj.city);
		formData.append('companyext', postObj.companyExt);
		formData.append('job_title', postObj.jobTitle);
		formData.append('performance', postObj.performanceNo);
		formData.append('zip', postObj.zipCode);
		formData.append('hire_date', postObj.hireDate);
		formData.append('pin', postObj.pin);

		formData.append('region_id', postObj.region.region_id);
		formData.append('job_class', postObj.jobClass.value);
		formData.append('branch_id', postObj.homeStore.code);
		formData.append('branch_id_2', postObj.store2.code);
		formData.append('branch_id_3', postObj.store3.code);
		formData.append('manager', postObj.manager.employee_id);
		formData.append('employee_type', postObj.associateType.value);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	//CHANGE THE ASSOCIATE PIN
	changePin(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'employee/change_pin/';
		const formData = new FormData();

		formData.append('assocaite_id', postObj.employeeId);
		formData.append('old_pin', postObj.oldPin);
		formData.append('new_pin', postObj.newPin);
		formData.append('confirm_pin', postObj.confirmPin);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	//GET ASSOCIATE PIN
	getAssociatePin(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'employee/verify_store_associate_pin/';
		const formData = new FormData();

		formData.append('ass_id', postObj.showEmployeeId);
		formData.append('manager_id', postObj.storeManagerPin);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	//GET SINGLE ITEM
	getItem(id) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'employee/edit_employee/' + id;
		return instance().get(api)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	// cHANGE ASSOCIATE STATUS
	changeStatus(data) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'employee/change_status/' + data.status + '/' + data.employeeId;
		return instance().get(api).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	verifyAssociateId(value) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'employee/verify_employee_id/' + value;
		return instance().get(api).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	verifyAssociatePin(value) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'employee/verify_pin/' + value;
		return instance().get(api).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}