import instance from './index';
import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";

export default class UserAuthentication {

	loginUser(branchId, branchPassword) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = '/login/authentication';
		const formData = new FormData();
		formData.append('branch_id', branchId);
		formData.append('branch_password', branchPassword);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	logoutUser() {
		const api = 'login/sign_out';
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		//sign_out
		return instance().get(api).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			});
	}
}